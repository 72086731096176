@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  @apply m-0 p-0 bg-jade-50
}

.transition-all {
  transition: all 300ms ease !important;
}

#Header .nav-link {
  @apply text-xl font-medium font-ff-lato capitalize py-1 px-4 border-[1px] rounded-[4px] border-jade-500 hover:border-jade-50 text-jade-50
}

#Header .nav-link.active {
  @apply bg-jade-50 text-jade-500 border-jade-50
}

.hero-sec .hero-title {
  @apply text-5xl font-bold leading-[1.2] font-ff-montserrat text-white
}

.hero-sec .hero-subtitle {
  @apply text-2xl font-medium py-2 font-ff-lato text-jade-50
}

.primary-btn {
  @apply normal-case bg-jade-500 hover:bg-jade-600
}

.overlay {
  @apply absolute z-[-1] top-0 bottom-0 left-0 right-0
}

.play-btn {
  @apply bg-white text-jade-500 hover:bg-jade-50 hover:scale-125
}

#video-popup .MuiDialogContent-root {
  @apply p-2
}

#video-popup video {
  @apply rounded
}

.play-btn {
  animation: pulse 1500ms infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px #ecfdf58a;
  }

  50% {
    box-shadow: 0 0 0 20px #ecfdf58a;
  }

  100% {
    box-shadow: 0 0 0 50px #ecfdf58a;
  }
}

.about-sec img {
  @apply w-full h-full object-cover rounded-md
}

.title-1 {
  @apply text-3xl font-ff-montserrat font-medium mb-2 text-jade-500
}

.content-1 {
  @apply text-xl font-ff-lato font-medium leading-[1.5]
}

.main-title {
  @apply font-ff-montserrat text-[40px] max-w-[800px] font-bold text-jade-500
}

.sec-title {
  @apply font-ff-montserrat font-medium text-2xl cursor-text hover:bg-transparent text-jade-800
}

.single-service {
  @apply hover:translate-y-[-4px] shadow-none hover:shadow-xl flex flex-col gap-5 h-full border-dashed border-[1px] rounded-md border-jade-500
}

.single-step {
  @apply bg-transparent shadow-none
}

.step-icon {
  @apply inline-block border-[2px] border-dashed rounded-full p-2 bg-jade-50 border-jade-500
}

.step-svg {
  @apply text-[130px] rounded-full p-2 text-jade-500
}

.single-step:hover .step-svg {
  @apply text-jade-100 bg-jade-500
}

#Footer .nav-link {
  @apply font-ff-lato text-lg leading-[1] hover:underline underline-offset-[2px] capitalize py-1 text-jade-50 hover:text-jade-200
}

#Footer .copy-right-area .nav-link {
  @apply text-base text-jade-700 hover:text-jade-950 transition-all
}

.author :nth-child(1) {
  @apply text-xl font-ff-lato capitalize text-jade-700
}

.author :nth-child(2) {
  @apply font-ff-lato text-jade-700
}

.custom-textarea textarea {
  @apply bg-transparent outline-none resize-none
}

textarea::-webkit-scrollbar {
  display: none;
}